import { EnvVarsForms } from '../pages/DetectingLogs';

export const handlePaste = (
  e: any,
  envVars: EnvVarsForms[],
  setEnvVars: (vars: EnvVarsForms[]) => void
) => {
  const pastedData = e.clipboardData.getData('Text');

  const parsedArray = pastedData.split('\n').reduce((acc: any, line: any) => {
    const firstEqualIndex = line.indexOf('=');
    if (firstEqualIndex !== -1) {
      const key = line.slice(0, firstEqualIndex);
      const value = line.slice(firstEqualIndex + 1);
      if (key) {
        acc.push({
          Name: key.trim(),
          Value: value.trim(),
          onChange: () => {},
          onRemove: () => {}
        });
      }
    }
    return acc;
  }, []);

  if (envVars.length > 0) {
    setEnvVars([...envVars, ...parsedArray]);
  } else {
    setEnvVars(parsedArray);
  }
};
