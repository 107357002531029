import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Fade,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Skeleton,
  Switch,
  Tooltip
} from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { FC, useEffect, useRef, useState } from 'react';
import { shallowEqual, TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { createSearchParams, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import DescriptionIcon from '../../assets/icons/Description';
import DesktopWindowsIcon from '../../assets/icons/DesktopWindows';
import KeyboardCommandIcon from '../../assets/icons/KeyboardCommand';
import SettingsIcon from '../../assets/icons/Settings';
import {
  BuildFlagEnum,
  BuildServiceBranchConfigRequest,
  BuildServiceBranchConfigResponse,
  DeployServiceBranchConfigRequest,
  DeployServiceBranchConfigResponse,
  EnvVars,
  PortConfig
} from '../../models';
import { ProjectService } from '../../services/API/ProjectService';
import {
  getConfigureProjectState,
  resetRepositoryDetails
} from '../../store/reducers/configureProjectReducer';
import { PageRoutes, PortRange } from '../../utils/constants';
import BuildSettings from './BuildSettings';
import ConfigProject from './ConfigProject';
import Style from './DetectingLogs.style';
import EnvironmentVariables from './EnvironmentVariables';
import PortSelect from './PortSelect';
import DemoTextFormat from '../../assets/images/DemoTextFormat.png';
import AddCircleBlueIcon from '../../assets/icons/AddCircleBlue';
import { RightRotate } from '../../assets/icons/RightRotate';
import GithubLogo from '../../assets/images/github.svg';
import Illustration from '../../assets/images/Illustration.svg';
import { BuildService } from '../../services/API/BuildService';
import { DeployService } from '../../services/API/DeployService';
import { DetectService } from '../../services/API/DetectService';
import { notifyError, notifySuccess } from '../../services/toster';
import { RootState } from '../../store/store';
import Stylecss from './DetactingLogs.module.css';
import SkeletonConfig from './SkeletonConfig.tsx';
import useAnalyticsEventTracker from '../../hooks/useAnalyticsEventTracker';
import { confirmDialog } from '../../components/shared/ConfirmDialog';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CloseIcon from '../../assets/icons/Close';
import AddIcon from '../../assets/icons/AddIcon';
import DownloadIcon from '../../assets/icons/DownloadIcon/DownloadIcon';
import WorldIcon from '../../assets/icons/WorldIcon/WorldIcon';
import { CostomDomain } from './CostomDomain';
import { EllipsisContent } from '../../components/shared/EllipsisContent';
import Loader from '../../components/shared/Loader';
import ExpandMoreIcon from '../../assets/icons/ExpandMore';
import TooltipWrapper from '../../components/shared/TooltipWrapper';
import { handlePaste } from '../../utils/helper';

export type GeneralForm = {
  Name: string;
  Source: string;
  Language: string;
  Dependencies: string;
  onChange(prop: any, value: string): void;
  nameDisable?: boolean;
};

export type Framework = {
  Name: string;
  Description: string;
  Logo: string;
  Settings: CommandType;
  Language: string;
  IsFrontendFramework: Boolean;
};

export type CommandType = {
  BuildCommand: CommandDetail;
  RunCommand: CommandDetail;
  InstallCommand: CommandDetail;
  OutputDirectory: CommandDetail;
  RootDirectory: CommandDetail;
  ExposePorts: Array<Number>;
};

export type CommandDetail = {
  Placeholder: string;
  Value: string;
  IsRequired: Boolean;
  IsOverride: Boolean;
  OverrideValue: string;
};

export type BuildSettingForm = {
  Image: string;
  Dockerfile: string;
  DockerfileContent: string;
  Context: string;
  dockerChecked: boolean;
  Framework?: Framework;
  BuildFlag?: BuildFlagEnum;
  onChange(prop: any, value: string | Boolean | Framework | BuildFlagEnum): void;
  handleDockerToggle(event: any): void;
};

export type EnvVarsForms = {
  Name: string;
  Value: string;
  onRemove(): void;
  onChange(prop: any, value: string): void;
};
export type DomainForms = {
  Domain: string;
  IsVerified: boolean;
  Tags: string[];
  CreatedAt: Date;
  UpdatedAt: Date;
  onRemove(): void;
  onChange(prop: any, value: string): void;
};

export type PortsForm = {
  Port: string;
  Protocol: string;
  HealthCheck: string;
  Index?: number;
  TotalLength?: number;
  IsDetect: boolean;
  onRemove(): void;
  onChange(prop: any, value: string): void;
};
const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;

export const DetectingLogs: FC = () => {
  const gaEventTracker = useAnalyticsEventTracker('cofiguration');
  const [genralConfig, setGeneralConfig] = useState<GeneralForm>({} as GeneralForm);
  const [buildSetting, setBuildSetting] = useState<BuildSettingForm>({} as BuildSettingForm);
  const [envVars, setEnvVars] = useState<EnvVarsForms[]>([]);
  const [domain, setDomain] = useState<DomainForms[]>([]);
  const [ports, setPorts] = useState<PortsForm[]>([]);
  const [isSkeleton, setIsSkeleton] = useState(true);
  const [isLoader, setIsLoader] = useState(false);
  const [fileValue, setFileValue] = useState({} as any);
  const [fileName, setFileName] = useState('');
  const [openDialog, setOpen] = useState(false);
  const [checked, setChecked] = useState(true);
  const [dockerChecked, setDockerChecked] = useState(false);
  const [loader, setLoader] = useState(false);
  const ref = useRef<any>();
  const [counter, setCounter] = useState(0);
  const [expand, setExpand] = useState<any>({
    general: true,
    build: false,
    port: false,
    env: false,
    domain: false
  });

  const handleOpen = (e: any) => {
    e.stopPropagation();

    setOpen(true);
  };
  const handleCloseDialog = (e: any) => {
    if (e) {
      e?.stopPropagation();
    }
    setFileName('');
    setOpen(false);
  };

  const handleChange = (event: any) => {
    setChecked(event.target.checked);
  };
  const handleDockerToggle = (event: any) => {
    if (expand.build) {
      event.stopPropagation();
    }
    toggleAcordion('build', 'click');
    setDockerChecked(event.target.value === 'true');
  };

  const [buildBranchConfig, setBuildBranchConfig] = useState<BuildServiceBranchConfigResponse>(
    {} as BuildServiceBranchConfigResponse
  );
  const [deployBranchConfig, setDeployBranchConfig] = useState<DeployServiceBranchConfigResponse>(
    {} as DeployServiceBranchConfigResponse
  );
  const repositoryDetails = useTypedSelector(getConfigureProjectState, shallowEqual);
  const [currentBranch, setCurrentBranch] = useState('');
  const [searchParams, setSearchParams] = useSearchParams();

  const { id } = useParams();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const projectService = new ProjectService();
  const buildAPIService = new BuildService();
  const deployAPIService = new DeployService();
  const detectAPIService = new DetectService();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const [listBuildBranchConfig, setListBuildBranchConfigs] = useState<
    BuildServiceBranchConfigResponse[]
  >([]);

  // Environment Variables Helper Methods

  function handleEnvVarsRemove(index: number) {
    let items = envVars;
    items.splice(index, 1);
    setEnvVars([...items]);
  }

  function handleDomainRemove(index: number) {
    let items = domain;
    items.splice(index, 1);
    setDomain([...items]);
  }

  const handleFileUpload = (e: any) => {
    e.stopPropagation();
    if (
      fileValue?.type == 'text/plain' ||
      (fileValue?.type == '' && fileValue?.name?.match('^.env.*$'))
    ) {
      var reader = new FileReader();
      try {
        reader.onload = function (e) {
          var content = reader.result;
          const array = content?.toString().replace(/\r\n/g, '\n').split('\n');
          var envArray: any = [];
          array?.map(
            (el, index) =>
              (envArray[index] = {
                Name: array[index]?.split('=')[0]?.trim(),
                Value: array[index]?.split('=')[1]?.trim()
              })
          );
          if (envVars.length > 0) {
            setEnvVars([...envVars, ...envArray]);
          } else {
            setEnvVars(envArray);
          }
        };
        reader.readAsText(fileValue);
        toggleAcordion('env', 'click');
        handleCloseDialog('');
      } catch (error) {
        notifyError('Please uplode valid file in shown format');
      }
    } else {
      notifyError('Please uplode .txt or .env.* file');
    }
  };
  function handleEnvVarsAdd(e: any) {
    if (expand.env) {
      e.stopPropagation();
    }
    let item = {} as EnvVarsForms;
    toggleAcordion('env', 'click');
    setEnvVars([...envVars, item]);
  }

  function handleDomainAdd(e: any) {
    if (expand.domain) {
      e.stopPropagation();
    }

    let item = {} as DomainForms;
    toggleAcordion('domain', 'click');
    setDomain([...domain, item]);
  }

  function handleCustomDomainChange(index: number, props: 'Domain', value: string) {
    let items = domain;
    items[index][props] = value;
    setDomain([...items]);
  }

  async function handleCustomDomainRefreshStatus(e: any) {
    if (expand.domain) {
      e.stopPropagation();
    }

    if (typeof id != 'undefined') {
      var result = await deployAPIService.GetRefreshCustomDomainStatus(
        id,
        currentBranch,
        searchParams.get('alias')
      );
      if (result) {
        notifySuccess('Congratulation! Your domain is verified.');
        const deployResult = await deployAPIService.GetProjectBranchConfig(
          id,
          currentBranch,
          searchParams.get('alias')
        );
        if (deployResult.Branch) {
          setDeployBranchConfig(deployResult);
        }
        clearInterval(ref.current);
      }
    }
  }

  function handleEnvVarsChange(index: number, props: 'Name' | 'Value', value: string) {
    let items = envVars;
    items[index][props] = value;
    setEnvVars([...items]);
  }

  // Ports Helper Methods

  function handlePortRemove(index: number) {
    let items = ports;
    items.splice(index, 1);
    setPorts([...items]);
  }

  function handlePortAdd() {
    let item = { Protocol: '', HealthCheck: '/' } as PortsForm;
    setPorts([...ports, item]);
  }

  function handlePortChange(
    index: number,
    props: 'Port' | 'Protocol' | 'HealthCheck',
    value: string
  ) {
    let items = ports;
    items[index][props] = value;
    setPorts([...items]);
  }

  // General Config

  function handleGeneralConfigChange(
    props: 'Name' | 'Source' | 'Language' | 'Dependencies',
    value: string
  ) {
    let item = genralConfig;
    item[props] = value;
    setGeneralConfig({ ...item });
  }

  // BuildSetting Config

  function handleBuildSettingConfigChange(props: any, value: string | BuildFlagEnum) {
    let item: any = buildSetting;
    item[props] = value;

    // setDockerChecked(item.DockerfileContent === ' ' || !item.DockerfileContent ? false : true);
    setBuildSetting({
      ...item,
      dockerChecked: item.DockerfileContent === ' ' || !item.DockerfileContent ? false : true
    });
  }

  // Final Submit Method

  function handleSetDeployRequest() {
    let deployRequest = {} as DeployServiceBranchConfigRequest;
    deployRequest.Env = [];
    deployRequest.Ports = [];
    deployRequest.CustomDomains = [];

    envVars.forEach((element) => {
      deployRequest.Env.push({
        Key: element.Name,
        Value: element.Value
      });
    });

    ports.forEach((element) => {
      deployRequest.Ports.push({
        Port: parseInt(element.Port),
        Protocol: element.Protocol,
        HealthCheck: element.HealthCheck
      });
    });

    domain.forEach((element) => {
      if (
        deployRequest.CustomDomains.findIndex(
          (s) => s.Domain.toLocaleLowerCase() == element.Domain.toLocaleLowerCase()
        ) == -1
      ) {
        deployRequest.CustomDomains.push({
          Domain: element.Domain,
          IsVerified: element.IsVerified,
          Tags: element.Tags,
          CreatedAt: element.CreatedAt,
          UpdatedAt: element.UpdatedAt
        });
      }
    });

    return deployRequest;
  }

  const handleExportEnv = (e: any) => {
    const data = envVars.map((el) => `${el.Name}=${el.Value}`).join('\n');

    if (envVars.length > 0 && envVars[0].Name && envVars[0].Value) {
      const text = data;
      const filename = `${id}-${currentBranch}`;
      const blob = new Blob([text], { type: 'application/octet-stream' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      link?.parentNode?.removeChild(link);
    }

    e.stopPropagation();
  };
  const timerForAutoRefres = (e: any) => {
    clearInterval(ref.current);

    ref.current = setInterval(() => {
      handleCustomDomainRefreshStatus(e);
    }, 10000);
  };

  function handleSetBuildRequest() {
    let buildRequest = {} as BuildServiceBranchConfigRequest;

    buildRequest.Dockerfile = buildSetting.Dockerfile;
    buildRequest.Context = buildSetting.Context;
    buildRequest.Language = genralConfig.Language;
    buildRequest.DockerfileContent = dockerChecked ? buildSetting.DockerfileContent : ' ';
    buildRequest.IsAutoDeploy = checked;
    buildRequest.Framework = buildSetting.Framework as Framework;
    buildRequest.BuildFlag = buildSetting.BuildFlag as BuildFlagEnum;
    if (buildSetting.BuildFlag == BuildFlagEnum.COMMANDS) {
      buildRequest.Framework.Settings.ExposePorts =
        buildRequest.Framework.Settings.ExposePorts.filter(Boolean).map(Number);
    }
    if (!buildSetting.BuildFlag) {
      if (!dockerChecked) {
        handleBuildSettingConfigChange('BuildFlag', BuildFlagEnum.DOCKERFILE);
      } else {
        handleBuildSettingConfigChange('BuildFlag', BuildFlagEnum.INLINE);
      }
    }
    return buildRequest;
  }

  async function handleSubmit() {
    setIsLoader(true);
    let isPortsValid = true;
    let isEnvVarsValid = true;
    let isDomainValid = true;

    if (
      buildSetting.BuildFlag === BuildFlagEnum.COMMANDS &&
      (!buildSetting.Framework?.Name ||
        !buildSetting.Framework?.Description ||
        !buildSetting.Framework?.Language)
    ) {
      notifyError('Please select an appropriate framework configs');
      setIsLoader(false);
      return;
    }

    ports.forEach((element) => {
      if (
        !element.Port ||
        +element.Port < PortRange.MinPortRange ||
        +element.Port > PortRange.MaxPortRange
      ) {
        isPortsValid = false;
        notifyError('Please enter valid port');
      }
    });

    envVars.forEach((element) => {
      if (!(element.Name && element.Value)) {
        isEnvVarsValid = false;
      }
    });

    domain.forEach((element) => {
      if (
        !(
          element.Domain &&
          element.Domain.match(/^[a-zA-Z0-9][a-zA-Z0-9-]{0,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+$/g)
        )
      ) {
        isDomainValid = false;
        notifyError('Please enter valid Domain');
      }
    });

    for (let index = 0; index < envVars.length; index++) {
      let targetIndex = envVars.findIndex((x) => {
        if (x.Name && x.Value && envVars[index].Name && envVars[index].Value) {
          return (
            x.Name.toLocaleLowerCase().trim() == envVars[index].Name.toLocaleLowerCase().trim() &&
            x.Value.toLocaleLowerCase().trim() == envVars[index].Value.toLocaleLowerCase().trim()
          );
        } else {
          return '';
        }
      });

      if (targetIndex != index && targetIndex != -1) {
        isEnvVarsValid = false;
        notifyError('Environment variables duplicate are not allowed');
      } else if (targetIndex === -1) {
        isEnvVarsValid = false;
        notifyError('Please enter valid Environment');
      }
    }

    for (let index = 0; index < domain.length; index++) {
      let targetIndex = domain.findIndex((x) => {
        if (x.Domain && domain[index].Domain) {
          return (
            x.Domain.toLocaleLowerCase().trim() == domain[index].Domain.toLocaleLowerCase().trim()
          );
        } else {
          return '';
        }
      });
      if (targetIndex != index && targetIndex != -1) {
        isDomainValid = false;
        notifyError('Domains duplicate are not allowed');
      } else if (targetIndex === -1) {
        isEnvVarsValid = false;
      }
    }

    if (isEnvVarsValid && isPortsValid && isDomainValid) {
      if (ports.length > 1) {
        notifyError('Please use only one port');
        return;
      }

      let buildRequest = handleSetBuildRequest();
      let deployRequest = handleSetDeployRequest();

      let isNew = searchParams.get('isNew');
      if (typeof id != 'undefined') {
        if (currentBranch) {
          await buildAPIService.UpdateBranchConfig(
            id,
            currentBranch,
            buildRequest,
            searchParams.get('alias')
          );
          await deployAPIService.UpdateBranchConfig(
            id,
            currentBranch,
            searchParams.get('alias'),
            deployRequest
          );
          dispatch(resetRepositoryDetails());
          if (isNew) {
            setIsLoader(false);
            gaEventTracker('success_create_project');
            if (searchParams.get('alias')) {
              navigate({
                pathname: PageRoutes.RepositoryDetails.replace(':id', id),
                search: createSearchParams({
                  branch: currentBranch,
                  isNew: 'true',
                  alias: searchParams.get('alias') || ''
                }).toString()
              });
            } else {
              navigate({
                pathname: PageRoutes.RepositoryDetails.replace(':id', id),
                search: createSearchParams({
                  branch: currentBranch,
                  isNew: 'true'
                }).toString()
              });
            }
          } else {
            setIsLoader(false);

            if (searchParams.get('alias')) {
              navigate({
                pathname: PageRoutes.RepositoryDetails.replace(':id', id),
                search: createSearchParams({
                  branch: currentBranch,
                  alias: searchParams.get('alias') || ''
                }).toString()
              });
            } else {
              navigate({
                pathname: PageRoutes.RepositoryDetails.replace(':id', id),
                search: createSearchParams({ branch: currentBranch }).toString()
              });
            }
          }
        } else {
          setIsLoader(false);
          notifyError('Branch Configuration not found');
          handleProjectDetailsNavigation(id);
        }
      } else {
        setIsLoader(false);
      }
    } else {
      setIsLoader(false);
    }
  }

  // Get Project Details
  async function getProjectById(id: string) {
    let response = await projectService.GetProjectById(id, searchParams.get('alias'));

    setIsSkeleton(false);
    if (!response?.ID) {
      navigate(PageRoutes.Dashboard);
    }

    setGeneralConfig({
      Name: response.Name || '',
      Source: response.Source || '',
      Language: '',
      Dependencies: '',
      nameDisable: true,
      onChange() {}
    });
  }

  async function handleGetBranchConfigDetails(projectName: string, branchName: string) {
    const buildResult = await buildAPIService.GetProjectBranchConfig(
      projectName,
      branchName,
      searchParams.get('alias')
    );
    if (buildResult.Branch) {
      setBuildBranchConfig(buildResult);
    } else {
      if (typeof id != 'undefined') {
        handleProjectDetailsNavigation(id);
      }
    }

    const deployResult = await deployAPIService.GetProjectBranchConfig(
      projectName,
      branchName,
      searchParams.get('alias')
    );
    if (deployResult.Branch) {
      setDeployBranchConfig(deployResult);
    } else {
      if (typeof id != 'undefined') {
        handleProjectDetailsNavigation(id);
      }
    }

    await handleListBranchConfig(projectName);
  }
  const handleRefresh = async () => {
    if (id) {
      setIsSkeleton(true);
      const result = await getDetection(id);

      let envs: EnvVars[] = [];
      let ports: PortConfig[] = [];
      if (result?.env) {
        Object.keys(result.env).forEach((key) => {
          envs.push({
            Key: key,
            Value: result.env[key]
          });
        });
      }
      if (result?.ports) {
        Object.keys(result.ports).forEach((key) => {
          ports.push({
            Port: parseInt(key),
            Protocol: result.ports[key],
            HealthCheck: ''
          });
        });
      }
      if (result.branch) {
        // ON NEW DETECT ALSO WE ARE SHOWING INLINE EDITOR
        let buildConfig: BuildSettingForm = {
          Dockerfile: result.build.dockerfile,
          Context: result.build.context,
          Image: '',
          DockerfileContent: result.build.instructions,
          onChange() {},
          handleDockerToggle() {},
          dockerChecked:
            result.build.instructions === ' ' || !result.build.instructions ? false : true
        };
        setDockerChecked(
          result.build.instructions === ' ' || !result.build.instructions ? false : true
        );
        setBuildSetting(buildConfig);
        setGeneralConfig({
          ...genralConfig,

          Language: result.language.toLocaleLowerCase()
        });
      }
      if (ports?.length === 0) {
        ports.push({
          Port: '',
          Protocol: '',
          HealthCheck: ''
        });
      }
      setDeployBranchConfig({
        ...deployBranchConfig,
        Env: envs,

        Ports: ports
      });
      setIsSkeleton(false);
    }
  };

  async function getDetection(id: string) {
    let response = await detectAPIService.DetectByBranch(
      id,
      currentBranch,
      searchParams.get('alias')
    );
    return response;
  }

  useEffect(() => {
    if (buildBranchConfig.Branch) {
      let buildConfig: BuildSettingForm = {
        Dockerfile: buildBranchConfig.Dockerfile,
        Context: buildBranchConfig.Context,
        Image: '',
        DockerfileContent: searchParams.get('isNew')
          ? repositoryDetails?.detect?.build?.instructions
          : buildBranchConfig.DockerfileContent,
        Framework: buildBranchConfig.Framework,
        BuildFlag: buildBranchConfig.BuildFlag,
        onChange() {},
        handleDockerToggle() {},
        dockerChecked
      };
      if (buildBranchConfig.BuildFlag) {
        if (buildBranchConfig.BuildFlag === BuildFlagEnum.DOCKERFILE) {
          buildConfig.dockerChecked = false;
          setDockerChecked(false);
        } else if (buildBranchConfig.BuildFlag === BuildFlagEnum.INLINE) {
          buildConfig.dockerChecked = true;
          setDockerChecked(true);
        }
      } else {
        buildConfig.dockerChecked =
          buildConfig.DockerfileContent === ' ' || !buildConfig.DockerfileContent ? false : true;
        setDockerChecked(
          buildConfig.DockerfileContent === ' ' || !buildConfig.DockerfileContent ? false : true
        );
      }
      setBuildSetting(buildConfig);
      setGeneralConfig({
        ...genralConfig,

        Language: buildBranchConfig.Language
      });
      setChecked(buildBranchConfig.IsAutoDeploy);
    }
  }, [buildBranchConfig]);

  useEffect(() => {
    if (buildSetting.BuildFlag !== BuildFlagEnum.COMMANDS) {
      if (!dockerChecked) {
        handleBuildSettingConfigChange('BuildFlag', BuildFlagEnum.DOCKERFILE);
      } else {
        handleBuildSettingConfigChange('BuildFlag', BuildFlagEnum.INLINE);
      }
    }
  }, [dockerChecked]);

  useEffect(() => {
    if (deployBranchConfig.Env) {
      let envs: EnvVarsForms[] = [];

      deployBranchConfig.Env.forEach((item) => {
        envs.push({
          onChange() {},
          onRemove() {},
          Name: item.Key,
          Value: item.Value
        });
      });
      setEnvVars(envs);
    }
    if (deployBranchConfig.Ports?.length > 0) {
      let ports: PortsForm[] = [];

      deployBranchConfig.Ports.forEach((item) => {
        ports.push({
          onChange() {},
          onRemove() {},
          Port: item.Port.toString(),
          Protocol: item.Protocol,
          HealthCheck: item.HealthCheck,
          IsDetect: deployBranchConfig.Ports.length > 1 ? true : false
        });
      });
      setPorts(ports);
    }

    if (deployBranchConfig.CustomDomains?.length > 0) {
      let internalDomains: DomainForms[] = [];

      deployBranchConfig.CustomDomains.forEach((item, index) => {
        internalDomains.push({
          onChange() {},
          onRemove() {},
          Domain: item.Domain,
          IsVerified: item.IsVerified,
          Tags: item.Tags,
          CreatedAt: item.CreatedAt,
          UpdatedAt: item.UpdatedAt
        });
      });
      if (!internalDomains[0].IsVerified) {
        timerForAutoRefres('');
      }
      setDomain(internalDomains);
    }
  }, [deployBranchConfig]);

  useEffect(() => {
    if (typeof id == 'undefined') {
      navigate(PageRoutes.Dashboard);
    } else {
      handleinit(id);
    }

    return () => {
      clearInterval(ref.current);
    };
  }, []);
  const toggleAcordion = (name: any, click: any) => {
    const value = expand[name];

    const item = { ...expand };
    if (click) {
      item[name] = true;
    } else {
      item[name] = !value;
    }

    setExpand(item);
  };
  async function handleinit(id: string) {
    handlePortAdd();
    await getProjectById(id);
    let cbranch = searchParams.get('branch');
    if (cbranch) {
      setCurrentBranch(cbranch);
      await handleGetBranchConfigDetails(id, cbranch);
    } else {
      notifyError('Branch Configuration not found');
      navigate(PageRoutes.Dashboard);
    }
  }

  async function handleBranchConfigDelete() {
    confirmDialog(
      `Delete Branch`,
      `Are you sure want to delete this branch ${currentBranch}?`,
      async () => {
        if (typeof id != 'undefined') {
          setLoader(true);
          var result = await buildAPIService.DeleteBranchConfig(
            id,
            currentBranch,
            searchParams.get('alias')
          );
          await deployAPIService.DeleteBranchConfig(id, currentBranch, searchParams.get('alias'));
          if (result) {
            notifySuccess('Branch deleted successfully');
            handleProjectDetailsNavigation(id);
            setLoader(false);
          }
        }
      },
      handleClose
    );
  }
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  async function handleListBranchConfig(projectName: string) {
    const buildResult = await buildAPIService.ListBranchConfig(
      projectName,
      searchParams.get('alias')
    );
    setListBuildBranchConfigs(buildResult);
  }

  function handleProjectDetailsNavigation(id: string) {
    if (searchParams.get('alias')) {
      navigate(
        PageRoutes.RepositoryDetails.replace(':id', id) + `?alias=${searchParams.get('alias')}`
      );
    } else {
      navigate(PageRoutes.RepositoryDetails.replace(':id', id));
    }
  }

  if (isLoader) {
    return <Loader isShow={isLoader} />;
  }
  return (
    <Container maxWidth="xl">
      <Box>
        <Box sx={Style.DetailsHeader}>
          <Box>
            {' '}
            <EllipsisContent
              variant="caption"
              sx={{
                fontSize: 24,
                fontWeight: '500',
                color: 'common.blackText',
                width: 'max-content',
                maxWidth: {
                  xxs: 280,
                  sm: 180,
                  md: 300,
                  lg: 400
                }
              }}
              text={id || ''}
            />
          </Box>
          <Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1.5,
                flexWrap: 'wrap',
                justifyContent: 'center'
              }}>
              <Box></Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  // width: 1,
                  justifyContent: 'flex-end'
                }}>
                <Typography sx={{ whiteSpace: 'nowrap' }}>Auto Deploy:</Typography>
                <Tooltip
                  arrow
                  TransitionComponent={Fade}
                  TransitionProps={{ timeout: 600 }}
                  title={'Auto Deploy On Git Push'}
                  placement={'top'}
                  sx={{ '&:hover': { backgroundColor: 'transparent' } }}>
                  <Switch
                    checked={checked}
                    onChange={handleChange}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                </Tooltip>
              </Box>

              <Box>
                <Box sx={Style.RefreshIcon}>
                  <Button
                    disabled={isSkeleton}
                    onClick={handleRefresh}
                    variant="outlined"
                    color="secondary"
                    size="large"
                    sx={{ px: 1, fontWeight: 500 }}>
                    {' '}
                    <Box sx={Style.ReDetectButtonHead}>
                      <RightRotate style={{ width: '24px', height: '24px' }} />
                    </Box>
                    Re-detect
                  </Button>
                </Box>
              </Box>
              <Button
                disabled={isSkeleton}
                variant="contained"
                color="secondary"
                size="large"
                onClick={handleSubmit}>
                Save
              </Button>
              <Box>
                <Tooltip arrow title={'More Settings'} placement={'top'}>
                  <Box>
                    <Button
                      id="menu-button"
                      aria-controls={open ? 'header-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? 'true' : undefined}
                      onClick={handleClick}
                      disabled={isSkeleton || loader}
                      sx={Style.SettingButton}>
                      <MoreVertIcon sx={{ m: 1 }} />
                    </Button>
                    {searchParams.get('alias') == null && (
                      <Menu
                        id="header-menu"
                        aria-labelledby="menu-button"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        PaperProps={{
                          elevation: 0,
                          sx: Style.MenuDropDown
                        }}
                        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
                        <MenuItem
                          disabled={listBuildBranchConfig.length <= 1}
                          onClick={handleBranchConfigDelete}
                          sx={{ color: 'error.main' }}>
                          Delete Branch Config
                        </MenuItem>
                      </Menu>
                    )}
                  </Box>
                </Tooltip>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Divider sx={{ my: 2 }} />
      <Box sx={Style.AccordianBoxes} id="domain-table-holder">
        <Accordion expanded={expand.general} sx={Style.AccordionWrapper}>
          <AccordionSummary
            onClick={() => toggleAcordion('general', '')}
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            sx={{
              '.MuiAccordionSummary-content': { m: 0 },
              py: 2
            }}
            id="panel1bh-header">
            {' '}
            <Box sx={Style.LogsCardHead}>
              <Box sx={Style.LogsCardTitle}>
                <SettingsIcon sx={Style.IconSize} color="secondary" />
                <Typography variant="h2" sx={Style.Heading}>
                  General
                </Typography>
              </Box>
              <Box sx={Style.BranchNameWrapper}>
                <img width={24} src={GithubLogo} alt="githubLogo" />
                <EllipsisContent
                  sx={{
                    fontSize: 18,
                    width: 'max-content',
                    maxWidth: {
                      xxs: 280,
                      sm: 190,
                      md: 300,
                      lg: 400
                    }
                  }}
                  ml={1}
                  text={currentBranch}
                />
              </Box>
            </Box>
          </AccordionSummary>
          <AccordionDetails sx={Style.AccordionDetails}>
            {isSkeleton ? (
              <Grid container spacing={3}>
                <SkeletonConfig />
                <SkeletonConfig />
                <SkeletonConfig />
              </Grid>
            ) : (
              <ConfigProject
                {...genralConfig}
                onChange={(props, value) => {
                  handleGeneralConfigChange(props, value);
                }}
              />
            )}
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expand.build} sx={Style.AccordionWrapper}>
          <AccordionSummary
            onClick={() => toggleAcordion('build', '')}
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            sx={{
              '.MuiAccordionSummary-content': { m: 0 },
              py: 2
            }}
            id="panel1bh-header">
            {' '}
            <Box sx={Style.LogsCardHead}>
              <Box sx={Style.LogsCardTitle}>
                <KeyboardCommandIcon sx={Style.IconSize} color="secondary" />
                <Typography variant="h2" sx={Style.Heading}>
                  Build
                </Typography>
                {buildSetting.BuildFlag === BuildFlagEnum.COMMANDS && (
                  <TooltipWrapper
                    infoText={[
                      '* Placeholder value will be default value, you can override by toggle switch.',
                      '* if your project does not require any of the below command then simply enable the input toggle and make the field empty to ignore that command. By default we consider each command important'
                    ]}
                  />
                )}
              </Box>
            </Box>
          </AccordionSummary>
          <AccordionDetails sx={Style.AccordionDetails}>
            {isSkeleton ? (
              <Grid container spacing={3}>
                <SkeletonConfig />
                <SkeletonConfig />
              </Grid>
            ) : (
              <BuildSettings
                {...buildSetting}
                dockerChecked={dockerChecked}
                onChange={(props, value) => {
                  handleBuildSettingConfigChange(props, value as string);
                }}
                handleDockerToggle={handleDockerToggle}
              />
            )}
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expand.port} sx={Style.AccordionWrapper}>
          <AccordionSummary
            onClick={() => toggleAcordion('port', '')}
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            sx={{
              '.MuiAccordionSummary-content': { m: 0 },
              py: 2
            }}
            id="panel1bh-header">
            {' '}
            <Box sx={Style.LogsCardHead}>
              <Box sx={Style.LogsCardTitle}>
                <DescriptionIcon sx={Style.IconSize} color="secondary" />
                <Typography variant="h2" sx={Style.Heading}>
                  Port
                </Typography>
              </Box>
              {/*Temprory we are removing adding port functionality */}
              {/* <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Button color="secondary" onClick={handlePortAdd}>
              <AddCircleBlueIcon />
            </Button>
          </Box> */}
            </Box>
          </AccordionSummary>
          <AccordionDetails sx={Style.AccordionDetails}>
            {isSkeleton ? (
              <>
                <Grid container spacing={3}>
                  <SkeletonConfig />
                  <SkeletonConfig />
                </Grid>
              </>
            ) : (
              <Grid container spacing={3} sx={{ pr: 3 }}>
                {ports.map((element, index) => (
                  <PortSelect
                    key={index + 1}
                    {...element}
                    onRemove={() => handlePortRemove(index)}
                    onChange={(props, value) => {
                      handlePortChange(index, props, value);
                    }}
                    Index={index}
                    TotalLength={ports.length}
                  />
                ))}
                {ports.length === 0 && (
                  <Box sx={Style.NoDataFoundWrapper}>
                    <Typography variant="h2">No ports to show</Typography>
                  </Box>
                )}
              </Grid>
            )}
          </AccordionDetails>
        </Accordion>
        <Accordion
          onPaste={(e) => handlePaste(e, envVars, setEnvVars)}
          expanded={expand.env}
          sx={Style.AccordionWrapper}>
          <AccordionSummary
            onClick={() => toggleAcordion('env', '')}
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            sx={{
              '.MuiAccordionSummary-content': { m: 0 },
              py: 2
            }}
            id="panel1bh-header">
            {' '}
            <Box sx={Style.LogsCardHead}>
              <Box sx={Style.LogsCardTitle}>
                <DesktopWindowsIcon sx={Style.IconSize} color="secondary" />
                <Typography variant="h2" sx={Style.Heading}>
                  Environment Variables
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Button onClick={handleOpen} sx={{ p: 0, minWidth: 0, mr: 2 }} color="secondary">
                  <Tooltip
                    arrow
                    TransitionComponent={Fade}
                    TransitionProps={{ timeout: 600 }}
                    placement={'top'}
                    title={'Import environment variables'}>
                    <Box sx={{ height: '32px', width: '32px' }}>
                      <DownloadIcon />{' '}
                    </Box>
                  </Tooltip>
                </Button>
                {envVars.length !== 0 && (
                  <Button onClick={handleExportEnv} sx={{ p: 0, minWidth: 0 }} color="secondary">
                    <Tooltip
                      arrow
                      TransitionComponent={Fade}
                      TransitionProps={{ timeout: 600 }}
                      placement={'top'}
                      title={'Export environment variables'}>
                      <Box sx={{ height: '32px', width: '32px', rotate: '180deg' }}>
                        <DownloadIcon />{' '}
                      </Box>
                    </Tooltip>
                  </Button>
                )}
                <Button onClick={handleEnvVarsAdd} color="secondary">
                  <Box sx={{ height: '32px', width: '32px' }}>
                    <AddCircleBlueIcon />
                  </Box>
                </Button>
                <Dialog
                  fullWidth
                  maxWidth="sm"
                  open={openDialog}
                  onClose={handleCloseDialog}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description">
                  <DialogTitle id="alert-dialog-title">
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                      }}>
                      <Typography
                        variant="subtitle2"
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          color: 'text.primary',
                          fontWeight: '600',
                          p: 1
                        }}>
                        Import environment variables
                      </Typography>
                      <IconButton
                        aria-label="close"
                        onClick={handleCloseDialog}
                        sx={{ color: 'text.primary' }}>
                        <CloseIcon />
                      </IconButton>
                    </Box>
                  </DialogTitle>
                  <Divider />
                  <DialogContent sx={{ px: { xs: 2, md: 3 }, pt: 0 }}>
                    <Box sx={{ width: 1, mt: 2 }}>
                      <Box sx={{ width: 0.6, m: 'auto' }}>
                        <Box sx={{ mb: 1 }}>
                          <Typography component={'span'} variant="caption">
                            Upload your env file (Support only .txt or .env.* extention)
                          </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                          <img width={'30%'} src={DemoTextFormat} />
                          <Typography component={'span'} variant="caption">
                            Please upload .txt or .env.* file in above format
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',

                            gap: 1,
                            m: 'auto',
                            mt: 2
                          }}>
                          <Box
                            sx={{
                              border: '1px solid #9D9D9D',
                              padding: 1,
                              height: '36px',

                              borderRadius: '4px',
                              width: 0.8,
                              whiteSpace: 'nowrap',

                              overflow: 'hidden',
                              textOverflow: 'ellipsis'
                            }}>
                            {fileName?.split('\\').pop()}
                          </Box>

                          <Button variant="contained" color="secondary" component="label">
                            Upload
                            <input
                              type="file"
                              hidden
                              onChange={(event: any) => {
                                setFileValue(event?.target?.files[0]);

                                setFileName(event.target.value);
                              }}
                            />
                          </Button>
                        </Box>
                      </Box>
                    </Box>
                  </DialogContent>
                  <DialogActions sx={{ px: { xs: 2, md: 3 }, pb: 3, pt: 0, mt: 4 }}>
                    <Button
                      variant="outlined"
                      sx={{ minWidth: 130, color: '#9D9D9D', borderColor: '#9D9D9D' }}
                      onClick={handleCloseDialog}>
                      Cancel
                    </Button>
                    <Button
                      onClick={handleFileUpload}
                      variant="contained"
                      color="secondary"
                      sx={{ minWidth: 130 }}>
                      Import
                    </Button>
                  </DialogActions>
                </Dialog>
              </Box>
            </Box>
          </AccordionSummary>
          <AccordionDetails sx={Style.AccordionDetails}>
            {isSkeleton ? (
              <Grid container spacing={3}>
                <SkeletonConfig />
                <SkeletonConfig />
              </Grid>
            ) : (
              <>
                {envVars.length === 0 ? (
                  <Box sx={Style.NoDataFoundWrapper} onClick={handleEnvVarsAdd}>
                    <Box sx={Style.EnviromentVariables}>
                      <Box className={Stylecss.NoDataEnvContainer}>
                        <Box className={Stylecss.EnvImage}>
                          <img src={Illustration} alt="Illustration" />
                          <Typography sx={Style.Subtitle2} variant="subtitle2">
                            Add new environment variable
                          </Typography>
                        </Box>
                        <Box className={Stylecss.EnvHoverImage}>
                          <Box className={Stylecss.EnvAddButtun} sx={{ mt: 4 }}>
                            <Button
                              variant="contained"
                              color="secondary"
                              size="large"
                              sx={Style.AddButton}
                              onClick={handleEnvVarsAdd}>
                              +
                            </Button>
                            <Typography>Click to add environment variable</Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                ) : (
                  <Grid container spacing={3}>
                    {envVars.map((element, index) => (
                      <EnvironmentVariables
                        {...element}
                        key={index + 1}
                        onRemove={() => handleEnvVarsRemove(index)}
                        onChange={(props, value) => {
                          handleEnvVarsChange(index, props, value);
                        }}
                      />
                    ))}
                  </Grid>
                )}
                {envVars.length >= 3 && (
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      alignItems: 'flex-end',
                      mr: 2,
                      mt: 2
                    }}>
                    <Button
                      onClick={handleEnvVarsAdd}
                      sx={{
                        border: '1px solid #DADADA'
                      }}
                      color="secondary">
                      {' '}
                      <AddIcon />
                      Add Environment Variables
                    </Button>
                  </Box>
                )}
              </>
            )}
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expand.domain} sx={Style.AccordionWrapper}>
          <AccordionSummary
            onClick={() => toggleAcordion('domain', '')}
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            sx={{
              '.MuiAccordionSummary-content': { m: 0 },
              py: 2
            }}
            id="panel1bh-header">
            {' '}
            <Box sx={Style.LogsCardHead}>
              <Box>
                <Box sx={Style.LogsCardTitle}>
                  <WorldIcon sx={Style.IconSize} />
                  <Typography variant="h2" sx={Style.Heading}>
                    Custom Domain
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {/* Only one custom domain support for now */}
                {domain.length > 0 && !domain[0].IsVerified && (
                  <Box sx={Style.RefreshIcon}>
                    <Tooltip
                      arrow
                      TransitionComponent={Fade}
                      TransitionProps={{ timeout: 600 }}
                      title={'Autorefresh Custom Domain Status'}
                      placement={'top'}
                      sx={{ '&:hover': { backgroundColor: 'transparent' } }}>
                      <IconButton
                        onClick={handleCustomDomainRefreshStatus}
                        sx={Style.ReDetectButton}>
                        <RightRotate />
                      </IconButton>
                    </Tooltip>
                  </Box>
                )}
                {domain.length < 1 && (
                  <Button onClick={handleDomainAdd} color="secondary">
                    <Box sx={{ height: '32px', width: '32px' }}>
                      <AddCircleBlueIcon />
                    </Box>
                  </Button>
                )}
              </Box>
            </Box>
          </AccordionSummary>
          <AccordionDetails sx={Style.AccordionDetails}>
            {isSkeleton ? (
              <Grid container spacing={3}>
                <SkeletonConfig />
                <SkeletonConfig />
              </Grid>
            ) : (
              <>
                {domain.length === 0 ? (
                  <Box sx={Style.NoDataFoundWrapper}>
                    <Box sx={Style.EnviromentVariables}>
                      <Box className={Stylecss.NoDataEnvContainer}>
                        <Box className={Stylecss.EnvImage}>
                          <img onClick={handleDomainAdd} src={Illustration} alt="Illustration" />
                          <Typography sx={Style.Subtitle2} variant="subtitle2">
                            Add new custom domain
                          </Typography>
                        </Box>
                        <Box className={Stylecss.EnvHoverImage}>
                          <Box className={Stylecss.EnvAddButtun} sx={{ mt: 4 }}>
                            <Button
                              variant="contained"
                              color="secondary"
                              size="large"
                              sx={Style.AddButton}
                              onClick={handleDomainAdd}>
                              +
                            </Button>
                            <Typography>Click to add custom domain</Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                ) : (
                  <Grid container spacing={3}>
                    {domain.map((element, index) => (
                      <CostomDomain
                        {...element}
                        key={index + 1}
                        onRemove={() => handleDomainRemove(index)}
                        onChange={(props, value) => {
                          handleCustomDomainChange(index, props, value);
                        }}
                      />
                    ))}
                  </Grid>
                )}
              </>
            )}
          </AccordionDetails>
        </Accordion>
      </Box>
    </Container>
  );
};
